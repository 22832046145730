import React from "react"
import '../styles/glossary-popup.css'

class GlossaryPopup extends React.Component {
    constructor() {
		super();
		// this.questionTextArea = React.createRef();
        this.state = {
            newValue: '',
            oldValue: this.props.text,
        };
    }

    close() {
        this.props.close();
    }

    applyOnlyHere() {

    }

    saveAndApply() {
        // To and from will always start as to lower case
        this.props.addToGlossary(this.language, this.state.oldValue.toLowerCase(), this.state.newValue.toLowerCase());
    }

    render() {
        const flag = () => {
            let flag = '';

            switch (this.language) {
                case 'english':
                    flag = '🇬🇧';
                    break;
                case 'french':
                    flag = '🇫🇷';
                    break;
                case 'german':
                    flag = '🇩🇪';
                    break;
            }

            return flag;
        };

        return (
            <div>
                <p className="glossary-title">Glossary</p>
                <div className="glossary-edit-container">
                    <span>{{ flag }}</span>
                    <input
                        v-model={this.state.oldValue}
                        type="text"
                    />
                    <font-awesome-icon
                        className="arrow-right-icon"
                        icon="arrow-right"
                    />
                    <input
                        v-model={this.state.newValue}
                        type="text"
                    />
                </div>
                <div className="glossary-save-container">
                    <button
                        className="glossary-action glossary-cancel"
                        onClick="close"
                    >
                        Cancel
                    </button>
                    <button
                        className="glossary-action glossary-apply"
                        onClick="applyOnlyHere"
                    >
                        Apply Only Here
                    </button>
                    <button
                        className="glossary-action glossary-save"
                        onClick="saveAndApply"
                    >
                        Save &amp; Apply
                    </button>
                </div>
            </div>
        )
    }
}

export default GlossaryPopup;


export const deeplTranslate = async (text, sourceLang, targetLang) => {
    const requestUrl = new URL("https://api.deepl.com/v2/translate");
    const params = {
        auth_key: process.env.DEEPL_API_KEY,
        text,
        target_lang: targetLang,
        source_lang: sourceLang
    };

    Object.keys(params).forEach(key => requestUrl.searchParams.append(key, params[key]));

    const translated = await fetch(requestUrl, {method: 'POST'}).then(response => {
        return response.json();
    }).then(res => {
        return res;
    });

    return translated;
}

export const hello = 'hello';

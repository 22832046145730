import React from 'react'

import comeAgainGIF from '../images/please-come-again.gif'

const Glossary = () => {
    return (
        <div className="please-come-again">
            <h1>Sorry This Tool isn't available yet...</h1>
            <img src={comeAgainGIF} alt="Otter dancing with a fish" />
        </div>
    )
}

export default Glossary;
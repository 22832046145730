import React, { useEffect, useState } from 'react'

const WhenInStockProfile = ({profile}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [productName, setProductName] = useState(null);
    const [variants, setVariants] = useState(null);

    useEffect(() => {
        fetch(
            // Add in the cors anywhere so we can actually get the data
            // We can make our own cors fix later
            `https://cors-anywhere.herokuapp.com/https://nldamp.nl${profile.productUrl}?format=json`,
            { method: "GET" }
        )
            .then(res => res.json())
            .then(response => {
                setProductName(response.product.fulltitle);
                setVariants(response.product.variants);
                setIsLoading(false);
            })
        .catch(error => {
            console.log(error);
            fetch(
                // Add in the cors anywhere so we can actually get the data
                // We can make our own cors fix later
                `https://cors-anywhere.herokuapp.com/https://nldamp.nl${profile.productUrl}?format=json`,
                { method: "GET" }
            )
                .then(res => res.json())
                .then(response => {
                    if (response && response.product) {
                        setProductName(response.product.fulltitle);
                        setVariants(response.product.variants);
                        setIsLoading(false);
                    } else {
                        console.log('PROBLEM AT');
                        console.log(`https://cors-anywhere.herokuapp.com/https://nldamp.nl${profile.productUrl}?format=json`);
                        console.log(response);
                    }
                })
        });
    }, []);

    return (
        <div className="product-container">
            {isLoading
                ? <p>Loading: {profile.productUrl} for variants {profile.variants.map((variant, index) => <p key={index}>{variant.variantId} </p>)}</p>
                : (
                    <div>
                        <h2 className="product-title">{productName}</h2>
                        <p className="emails">Number of Emails: {profile.emails.length}</p>
                        {variants ?
                            profile.variants.map((variant, index) => {
                                return (
                                    <p
                                        className="variant"
                                        key={index}
                                    >
                                        Sku: {variants[variant.variantId].sku} = {variants[variant.variantId].title} ({variant.count})
                                    </p>
                                )
                            })
                            : <p className="variant">Only One Variant</p>
                        }
                    </div>
                )
            }
        </div>
    );
};

export default WhenInStockProfile;
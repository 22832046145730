import React from "react"
import '../styles/translated-product.css'

class TranslatedProduct extends React.Component {
    constructor() {
		super();
		// this.questionTextArea = React.createRef();
        this.state = {
            showProduct: false,
        };
    }

    toggleOpen() {
        this.showProduct = !this.showProduct;
    }

    render() {
        let showProduct = '';

        if (this.state.showProduct) {
            showProduct = (
                <div>
                    <div className="titles-container">
                        <div className="title-long-container">
                            <p className="section-title">Title Long</p>
                            {Object.keys(this.props.this.props.translatedObject.english).length > 0
                                ? <p
                                    v-if=""
                                    className="language-content"
                                    language="dutch"
                                >
                                    🇳🇱: {this.props.this.props.translatedObject.dutch.Title_Long}
                                </p> : ''
                            }
                            {Object.keys(this.props.this.props.translatedObject.english).length > 0
                                ? <p
                                    v-if=""
                                    className="language-content"
                                    language="english"
                                >
                                    🇬🇧: {this.props.this.props.translatedObject.english.Title_Long}
                                </p> : ''
                            }
                        </div>
                        <div className="title-short-container">
                            <p className="section-title">Title Short</p>
                            {Object.keys(this.props.this.props.translatedObject.english).length > 0
                                ? <p
                                    v-if=""
                                    className="language-content"
                                    language="dutch"
                                >
                                    🇳🇱: {this.props.this.props.translatedObject.dutch.Title_Short}
                                </p> : ''
                            }
                            {Object.keys(this.props.this.props.translatedObject.english).length > 0
                                ? <p
                                    v-if=""
                                    className="language-content"
                                    language="english"
                                >
                                    🇬🇧: {this.props.this.props.translatedObject.english.Title_Short}
                                </p> : ''
                            }
                        </div>
                    </div>
                    <div className="description-short-container">
                        <p className="section-title">Short Description</p>
                        {Object.keys(this.props.this.props.translatedObject.english).length > 0
                            ? <p
                                v-if=""
                                className="language-content"
                                language="dutch"
                            >
                                🇳🇱: {this.props.translatedObject.dutch.Description_Short}
                            </p> : ''
                        }
                        {Object.keys(this.props.this.props.translatedObject.english).length > 0
                            ? <p
                                v-if=""
                                className="language-content"
                                language="english"
                            >
                                🇬🇧: {this.props.translatedObject.english.Description_Short}
                            </p> : ''
                        }
                    </div>
                    <div className="description-long-container">
                        <p className="section-title">Long Description</p>
                        {Object.keys(this.props.this.props.translatedObject.english).length > 0
                            ? <p
                                v-if=""
                                className="language-content"
                                language="dutch"
                            >
                                🇳🇱: {this.props.translatedObject.dutch.Description_Long}
                            </p>: ''
                        }
                        {Object.keys(this.props.this.props.translatedObject.english).length > 0
                            ? <p
                                v-if=""
                                className="language-content"
                                language="english"
                            >
                                🇬🇧: {this.props.translatedObject.english.Description_Long}
                            </p> : ''
                        }
                    </div>
                    <div className="meta-container">
                        <div className="meta-container-left">
                            <div className="meta-title-container">
                                <p className="section-title">Meta Title</p>
                                {Object.keys(this.props.this.props.translatedObject.english).length > 0
                                    ? <p
                                        v-if=""
                                        className="language-content"
                                        language="dutch"
                                    >
                                        🇳🇱: {this.props.translatedObject.dutch.Meta_Title}
                                    </p>: ''
                                }
                                {Object.keys(this.props.this.props.translatedObject.english).length > 0
                                    ? <p
                                        v-if=""
                                        className="language-content"
                                        language="english"
                                    >
                                        🇬🇧: {this.props.translatedObject.english.Meta_Title}
                                    </p> : ''
                                }
                            </div>
                            <div className="meta-keywords-container">
                                <p className="section-title">Meta Keywords</p>
                                {Object.keys(this.props.this.props.translatedObject.english).length > 0
                                    ? <p
                                        v-if=""
                                        className="language-content"
                                        language="dutch"
                                    >
                                        🇳🇱: {this.props.translatedObject.dutch.Meta_Keywords}
                                    </p>: ''
                                }
                                {Object.keys(this.props.this.props.translatedObject.english).length > 0
                                    ? <p
                                        v-if=""
                                        className="language-content"
                                        language="english"
                                    >
                                        🇬🇧: {this.props.translatedObject.english.Meta_Keywords}
                                    </p> : ''
                                }
                                </div>
                            </div>
                            <div className="meta-container-right">
                                <div className="meta-description-container">
                                    <p className="section-title">Meta Description</p>
                                    {Object.keys(this.props.this.props.translatedObject.english).length > 0
                                        ? <p
                                            v-if=""
                                            className="language-content"
                                            language="dutch"
                                        >
                                            🇳🇱: {this.props.translatedObject.dutch.Meta_Description}
                                        </p>: ''
                                    }
                                    {Object.keys(this.props.this.props.translatedObject.english).length > 0
                                        ? <p
                                            v-if=""
                                            className="language-content"
                                            language="english"
                                        >
                                            🇬🇧: {this.props.translatedObject.english.Meta_Description}
                                        </p>: ''
                                    }
                                </div>
                            </div>
                        </div>
                    <div className="url-container">
                        <p className="section-title">Product URL</p>
                        {Object.keys(this.props.this.props.translatedObject.english).length > 0
                            ? <p
                                v-if=""
                                className="language-content"
                                language="dutch"
                            >
                                🇳🇱: {this.props.translatedObject.dutch.URL}
                            </p>: ''
                        }
                        {Object.keys(this.props.this.props.translatedObject.english).length > 0
                            ? <p
                                v-if=""
                                className="language-content"
                                language="english"
                            >
                                🇬🇧: {this.props.translatedObject.english.URL}
                            </p>: ''
                        }
                    </div>
                </div>
            )
        } 
        return (
            <li className="product-container">
                <p style="display: none;">{this.props.this.props.translatedObject.index}</p>
                <div
                    className="list-item-front"
                    style={showProduct ? {borderBottom: ".5px #222 solid"} : {}}
                >
                <span className="front-title">
                    {this.props.this.props.translatedObject.english.Title_Long}
                </span>
                {/* <font-awesome-icon
                    className="down-arrow-icon"
                    onClick="toggleOpen"
                    :icon="showProduct ? 'angle-up' : 'angle-down'"
                /> */}
                <span className="front-id">
                    {this.props.this.props.translatedObject.id}
                </span>
                </div>
                {showProduct}
            </li>
        )
    }
}

export default TranslatedProduct;

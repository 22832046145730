import React, { useState} from 'react'
import { useFirebase } from "gatsby-plugin-firebase"

import '../styles/whenInStockAnalytics.scss';

import WhenInStockProfile from './WhenInStockProfile';

const WhenInStockAnalytics = () => {
    const [customers, setCustomers] = useState(null);
    const [productsList, setProductsList] = useState(null);

    useFirebase(firebase => {
        firebase
        .firestore()
        .collection("email_when_instock")
        .get()
        .then(querySnapshot => {
            const customersFromDatabase = [];
            const products = [];

            querySnapshot.forEach((doc) => {
                const docData = doc.data();

                // Push 
                customersFromDatabase.push(docData);

                /* Add in the product data to the products variable */
                // Check to see if the product is in the variable
                // .producturl
                const {
                    producturl: productUrl,
                    variant: variantId,
                    email,
                    has_been_sent: hasBeenSent
                } = docData;

                // Only track the ones that haven't been sent yet
                if (!hasBeenSent) {
                    if (products.lenth === 0) { // This means there are no products in the list yet
                        products.push({
                            productUrl,
                            variants: [{
                                variantId: variantId,
                                count: 1,
                            }],
                            emails: [email]
                        });
                    } else {
                        // Check if the product is there yet
                        let indexOfProduct = false;

                        products.forEach((product, index) => {
                            if (product.productUrl === productUrl) {
                                // This is the product
                                indexOfProduct = index;
                            }
                        });

                        if (indexOfProduct === false) {
                            products.push({
                                productUrl,
                                variants: [{
                                    variantId,
                                    count: 1,
                                }],
                                emails: [email]
                            });
                        } else {
                            // The product already exists in the products array
                            // Check to see if the variant is already in there
                            const productThatMatches = products[indexOfProduct];
                            const productVariants = productThatMatches.variants;
                            let indexOfVariantFound = false;

                            productVariants.forEach((variant, index) => {
                                if (variant.variantId === variantId) {
                                    indexOfVariantFound = index;
                                }
                            });

                            // Check to see if we found that variant in there
                            if (indexOfVariantFound !== false) {
                                // Just change the count of that variant
                                const currentVariantCount = products[indexOfProduct].variants[indexOfVariantFound].count;

                                products[indexOfProduct].variants[indexOfVariantFound].count = currentVariantCount + 1;

                                // Push the email to the list
                                // @TODO Make sure there are no duplicates
                                products[indexOfProduct].emails.push(email);
                            } else {
                                // In this case we don't have that variant yet
                                // so we should push that variant to the products variant list
                                products[indexOfProduct].variants.push({
                                    variantId,
                                    count: 1,
                                });

                                // Push the email to the list
                                // @TODO Make sure there are no duplicates
                                products[indexOfProduct].emails.push(email);
                            }
                        }
                    }
                }
            });

            setCustomers(customersFromDatabase);
            setProductsList(products);
        })
    }, [])

    return (
        <div className="when-in-stock-analytics">
            {customers ? '' : "Loading..."}
            <div className="stats">
                <div className="stat-block">
                    <p className="number">{customers ? customers.length : 'Loading'}</p>
                    <p className="title">Total Number Of Submits</p>
                </div>
                <div className="stat-block">
                    <p className="number">{customers ? customers.filter((obj) => obj.has_been_sent).length : 'loading'}</p>
                    <p className="title">Emails sent</p>
                </div>
                <div className="stat-block">
                    <p className="number">{customers ? customers.filter((obj) => !obj.has_been_sent).length : 'loading'}</p>
                    <p className="title">Emails not sent yet</p>
                </div>
            </div>
            <div className="products-container">
                {
                    productsList ?
                        productsList.map((profile, index) => {
                            return <WhenInStockProfile key={index} profile={profile} />
                        })
                    : ''
                }
            </div>
        </div>
    );
}

export default WhenInStockAnalytics;
import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import Dashboard from "../components/Dashboard"
import BlogFaq from "../components/BlogFAQ"
import TranslateCSV from "../components/TranslateCSV"
import Glossary from "../components/Glossary"
import WhenInStockAnalytics from "../components/WhenInStockAnalytics"
import Login from "../components/Login"
import PrivateRoute from "../components/PrivateRoute"
import Status from "../components/Status"

const App = () => (
  <Layout>
    <Status />
    <Router>
      <PrivateRoute path="/app/dashboard" component={Dashboard} />
      <PrivateRoute path="/app/blogfaq" component={BlogFaq} />
      <PrivateRoute path="/app/translate-csv" component={TranslateCSV} />
      <PrivateRoute path="/app/glossary" component={Glossary} />
      <PrivateRoute path="/app/when-in-stock-analytics" component={WhenInStockAnalytics} />
      <Login path="/app/login" />
    </Router>
  </Layout>
)

export default App

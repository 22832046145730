export const splitValue = (value, index) => {
    const arrayString = value.substring(0, index) + 'super-complicated-replacement for array' + value.substring(index);

    return arrayString.split('super-complicated-replacement for array');
}

export const capitalize = s => {
    if (typeof s !== 'string') {
        return ''
    }

    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const replaceTerm = (string, from, to) => {
    // Check to see if the string is just the from
    // +2 is to include "" in the calculation
    if (from.length + 2 >= string.length) {
        string = string.replace(from, to)
    } else {
        let position = 0;

        while (true) {
            position = string.indexOf(from, position);

            if (position >= 0) {
                const whereFromEnds = position + from.length;
                const characterBefore = string.charAt(position - 1)
                const characterAfter = string.charAt(whereFromEnds)

                // Check if eligable for replacement
                if ((position === 0 && characterAfter === ' ') // Beginning of the string and words after that
                    || (position === 0 && characterAfter === ',') // Beginning of the string and in a list
                    || (characterBefore === '>' && characterAfter === ' ') // Beginning of inside HTML element
                    || (characterBefore === ' ' && characterAfter === '</') // End of inside HTML element
                    || (characterBefore === ' ' && characterAfter === ' ') // Just another word in a sentence
                    || (characterBefore === ' ' && characterAfter === ',') // Part of a list
                    || (characterBefore === ' ' && position + from.length === string.length) // End of a string
                ) {
                    if (position !== 0) {
                        // Split the string just before the from value
                        const splitBeforeFrom = splitValue(string, position - 1)
                        // Replace just that value since we know now that it's legit
                        const stringAfterReplaced = splitBeforeFrom[1].replace(from, to)

                        // Put back together again!
                        string = splitBeforeFrom[0] + stringAfterReplaced
                    } else {
                        // At the beginning of the value so we don't need to split the array
                        string = string.replace(from, to)
                    }
                }

                // After replacing search again from end of replaced word
                position += to.length;
            } else {
                break;
            }
        }
    }

    return string;
}


// TODO: Deal with Capitals
export const replaceGlossaryTerm = (string, from, to) => {
    // Capitalise and run again
    const capitalizedFrom = capitalize(from)
    const capitalizedTo = capitalize(to)

    if (string.includes(from)) {
        string = replaceTerm(string, from, to)
    }

    if (string.includes(capitalizedFrom)) {
        string = replaceTerm(string, capitalizedFrom, capitalizedTo)
    }

    return string;
};

export const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

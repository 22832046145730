const semiColonReplacement = '&&&tylersSuperSophisticatedSemiColonReplacement';
const doublequotations = '&&&tylers super complicated double doubles';
const doubleSpacing = 7 + doublequotations.length;

export const processCSV = csv => {
    const lines = csv.split('\r');
    const result = [];
    // NOTE: If your columns contain commas in their values, you'll need
    // to deal with those before doing the next step
    // (you might convert them to &&& or something, then covert them back later)
    // jsfiddle showing the issue https://jsfiddle.net/
    const headers = lines[0].split(',');

    

    for (let i = 1; i < 3; i += 1) {
        const obj = {};
        // We have a problem where there are style tags with semi colons messing this up
        // Replace all the `;` within style tags
        let position = 0;
        let finishedString = lines[i].replace(/""/g, doublequotations);

        while (true) {
            position = finishedString.indexOf(`style=${doublequotations}`, position + 1);

            if (position >= 0) {
                // This means we found a style tag
                // Let's replace the semi colons between here and the next `""`
                const whereStyleStarts = position + doubleSpacing;
                const whereStyleEnds = finishedString.indexOf(doublequotations, whereStyleStarts);
                const fullStyleString = finishedString.slice(whereStyleStarts, whereStyleEnds);
                const fullStyleStringWithoutSemiColons = fullStyleString.replace(/,/g, semiColonReplacement);
                
                finishedString = finishedString.substring(0, whereStyleStarts) + fullStyleStringWithoutSemiColons + finishedString.substring(whereStyleEnds);
            } else {
                break;
            }
        }

        let position2 = 0;

        while (true) {
            position2 = finishedString.indexOf(',"', position2 + 1);

            if (position2 >= 0) {
                // This means we found a style tag
                // Let's replace the semi colons between here and the next `""`
                const whereStyleStarts = position2 + 2;
                const whereStyleEnds = finishedString.indexOf('"', whereStyleStarts);
                const fullStyleString = finishedString.slice(whereStyleStarts, whereStyleEnds);
                const fullStyleStringWithoutSemiColons = fullStyleString.replace(/,/g, semiColonReplacement);

                finishedString = finishedString.substring(0, whereStyleStarts) + fullStyleStringWithoutSemiColons + finishedString.substring(whereStyleEnds);
            } else {
                break;
            }
        }

        const currentline = finishedString.split(',');

        // Then split
        // Replace bring back all the colons and double quotes to where they belong
        for (let j = 0; j < headers.length; j += 1) {
            const regexForSemiColon = new RegExp(semiColonReplacement, 'g');
            const regexForDoubleQuotes = new RegExp(doublequotations, 'g');

            obj[headers[j]] = currentline[j] ? currentline[j].replace(regexForSemiColon, ',').replace(regexForDoubleQuotes, '""') : currentline[j];
        }

        result.push(obj)
    }

    return {
        headers,
        result,
    };
}

export const getHeaders = input => {
    const lines = input.split(/\r|\n/);
    const headers = lines[0].split(';');

    return headers;
};

export const downloadBlob = (blob, filename) => {
    // Create an object URL for the blob object
    const url = URL.createObjectURL(blob);
    
    // Create a new anchor element
    const a = document.createElement('a');
    
    // Set the href and download attributes for the anchor element
    // You can optionally set other attributes like `title`, etc
    // Especially, if the anchor element will be attached to the DOM
    a.href = url;
    a.download = filename || 'download';
    
    // Click handler that releases the object URL after the element has been clicked
    // This is required for one-off downloads of the blob content
    const clickHandler = () => {
        setTimeout(() => {
        URL.revokeObjectURL(url);
        this.removeEventListener('click', clickHandler);
        }, 150);
    };
    
    // Add the click event listener on the anchor element
    // Comment out this line if you don't want a one-off download of the blob content
    a.addEventListener('click', clickHandler, false);
    
    // Programmatically trigger a click on the anchor element
    // Useful if you want the download to happen automatically
    // Without attaching the anchor element to the DOM
    // Comment out this line if you don't want an automatic download of the blob content
    a.click();
    
    // Return the anchor element
    // Useful if you want a reference to the element
    // in order to attach it to the DOM or use it in some other way
    return a;
};

export const translationKeys = [
    'Description_Long',
    'Description_Short',
    'Meta_Description',
    'Meta_Keywords',
    'Meta_Title',
    'Title_Long',
    'Title_Short',
    'URL',
    'Variant',
];

export const englishKeys = () => {
    translationKeys.map(key => {
        return `EN_${key}`;
    });
}

export const dutchKeys = () => {
    translationKeys.map(key => {
        return `NL_${key}`;
    });
}
import React from "react"
import '../styles/translatecsv.css'
// Components
import Flag from './Flag'
import TranslatedProduct from './TranslatedProduct'
import GlossaryPopup from './GlossaryPopup'
import { replaceGlossaryTerm, asyncForEach } from '../utils/utils'
import { deeplTranslate } from '../utils/deepl-translate'
import { translationKeys } from '../utils/consts';
import slugify from 'slugify';
import { downloadBlob } from '../utils/process-csv'

// Until we are done here
import comeAgainGIF from '../images/please-come-again.gif'

class TranslateCSV extends React.Component {
	constructor() {
		super();
		// this.questionTextArea = React.createRef();
		this.state = {
            englishGlossary: [],
            // frenchGlossary: [],
            // spanishGlossary: [],
            // germanGlossary: [],
            fileChosen: false,
            fileLoading: false,
            fileLoaded: false,
            startedTranslation: false,
            translating: false,
            translated: false,
            csvReadyToDownload: false,
            encodedUri: false,
            headers: [],
            csvFileObject: {},
            totalCharacterCount: 0,
            totalProducts: 0,
            englishPresent: 0,
            frenchPresent: 0,
            germanPresent: 0,
            spanishPresent: 0,
            glossaryText: '',
            glossaryLanguage: '',
            showGlossary: false,
            productTranslationResult: [],
            stringsToTranslateInCalculation: [],
            stringsAlreadyTranslated: [],
            charactersTranslated: 0,
            progressBarPercentage: 0,
            isError: false,
        }
    }
    
    setUpDashboard() {
        // Look for total products
        this.state.totalProducts = this.state.csvFileObject.length
        // check languages total
        this.state.englishPresent = this.state.headers.includes('EN_Title_Long') ? 1 : 0
        // this.frenchPresent = this.headers.includes('FR_Title_Long') ? 1 : 0
        // this.germanPresent = this.headers.includes('DE_Title_Long') ? 1 : 0
        // this.spanishPresent = this.headers.includes('ES_Title_Long') ? 1 : 0

        this.state.csvFileObject.forEach(product => {
            let translationCharacterCount = 0
            // Check strings against list of to be traslated strings
            let arrayOfTranslateableStrings = [];

            // Go throught each string to see if it's already been translated
            translationKeys.forEach(key => {
                if (product[`EN_${key}`] === ''
                    && product[`NL_${key}`] !== ''
                ) {
                    arrayOfTranslateableStrings.push(product[`NL_${key}`])
                }
            });

            arrayOfTranslateableStrings.forEach(string => {
                // If this string isn't included in our list yet we should add it to the list
                // and add number of characters to character count to be translated
                if (!this.stringsToTranslateInCalculation.includes(string)) {
                    translationCharacterCount += string.length;
                    this.stringsToTranslateInCalculation.push(string)
                }
            })

            // Add to the total count
            this.setState({
                totalCharacterCount: this.state.totalCharacterCount + translationCharacterCount,
            });
        })
    }

    async startTranslation() {
        // set UI
        this.setState({startedTranslation: true})

        let counter = 0;

        this.setState({
            progressBarPercentage: 0,
        });

        await setTimeout(async () => {
            await asyncForEach(this.state.csvFileObject, async (product, index) => {
                await setTimeout(async () => {
                    let englishResult = {};
                    let germanResult = {};
                    let spanishResult = {};
                    let dutch = {};

                    translationKeys.forEach(key => {
                        dutch[key] = product[`NL_${key}`];
                    })

                    const finalResult = {
                        index,
                        id: product.Internal_Variant_ID,
                        csvData: product,
                        dutch,
                        english: {},
                        german: {},
                        spanish: {},
                        french: {},
                    }

                    if (this.state.englishPresent) {
                        // Translate into english
                        // Check to make sure it hasn't been translated yet
                        translationKeys.forEach(async key => {
                            if (product[`EN_${key}`] === '' && product[`NL_${key}`] !== '') {
                                englishResult[key] = await this.translateString(product[`NL_${key}`], 'EN')

                                if (key === 'URL') {
                                    englishResult[key] = slugify(englishResult[key])
                                }
                            } else {
                                // If already translated add in as is
                                englishResult[key] = product[`EN_${key}`];
                            }
                        })

                        finalResult.english = englishResult;
                    }

                    

                    // update progress bar
                    this.setState({
                        productTranslationResult: this.state.productTranslationResult.push(finalResult),
                        progressBarPercentage: (this.state.productTranslationResult.length / this.state.csvFileObject.length) * 100,
                    });

                    // check if complete
                    if (this.state.csvFileObject.length === this.productTranslationResult.length) {
                        this.setState({
                            translated: true,
                        });
                    }
                }, 100);
            })
        }, 1000);
    }

    exportCSV() {
        // create an array of all the new csv datas
        this.setState({
            translated: false,
        })
        let newCSVDataArray = [];

        // set all the languages to the csv data
        this.state.productTranslationResult.forEach(product => {
            const csvObject = product.csvData;

            // Set the new language
            translationKeys.forEach(key => {
                csvObject[`EN_${key}`] = product.english[key];
            })

            newCSVDataArray.push(csvObject)
        });

        const csvArray = newCSVDataArray;

        // Turn the array of objects into an array of rows
        let csvContent = csvArray.map(row => {
            let rowArray = [];

            this.state.headers.forEach(header => {
                // check to see if it includes style=" then double quote it so it will be parsed correctly
                let newValue = row[header];

                if (newValue !== '') {
                    newValue = newValue.replace(/"/g, '""');
                } else {
                    newValue = '';
                }

                rowArray.push(`"${newValue}"`)
            })

            return rowArray.join(';')
        }).join('\r'); // Then join all the rows together

        // Add in the headers to the top of the file
        csvContent = this.state.headers.join(';') + '\r' + csvContent;

        const blob = new Blob(
            [ csvContent ],
            { type: 'text/csv' }
        );

        // Create a download link for the blob content
        const downloadLink = downloadBlob(blob, 'products.csv');

        // Attach the link to the DOM
        document.body.appendChild(downloadLink);

        this.setState({
            csvReadyToDownload: true,
        })
    }

    async translateString(text, language) {
        let translatedText = '';

        // Make sure there is something to translate
        if (Boolean(text)) {
            // Check to see if the string has already been translated
            this.stringsAlreadyTranslated.forEach(translation => {
                if (text === translation.dutch // In list
                    && translation[language] // and translated to the current language we are translating to
                ) {
                    // We have translated this before and should set the previous value to this
                    translatedText = translation[language]
                }
            })

            // If it's not in our records yet we need to translate it then add it to the list
            if (!Boolean(translatedText)) {
                let numberOfTrys = 0;
                let gotTranslation = false;

                while(numberOfTrys < 2 && !gotTranslation) {
                    numberOfTrys += 1;
                    try {
                        const { translations } = await deeplTranslate(
                            text,
                            'NL',
                            language
                        )

                        translatedText = translations[0].text

                        // DUMMY DATA
                        // translatedText = text

                        this.setState({
                            charactersTranslated: this.state.charactersTranslated += text.length
                        })

                        // Add to translation record
                        // If one doesn't exist yet for this 
                        const newTranslationRecord = {
                            dutch: text,
                        }

                        // Set translation
                        newTranslationRecord[language] = translatedText;

                        this.setState({
                            stringsAlreadyTranslated: this.state.stringsAlreadyTranslated.push(newTranslationRecord)
                        })

                        gotTranslation = true;
                    } catch (error) {
                        console.error(error);
                        // this.isError = true;
                        await setTimeout(() => {
                            if (numberOfTrys > 5) {
                                gotTranslation = true;
                            }
                        }, 5000);
                    }
                }
            }

            // check the glossary for anything that needs to be replaced
            if (language === 'EN' && translatedText !== '') {
                this.state.englishGlossary.forEach(term => {
                    translatedText = replaceGlossaryTerm(translatedText, term.from, term.to)
                });
            }
        }

        return translatedText;
    }

    getSelectionText() {
        if (window.getSelection) {
            const selection = window.getSelection();
            const text = selection.toString();
            const selectedElement = selection.focusNode;

            if (selectedElement) {
                // Check to make sure that the selected text is something that we want
                const selectedElementParent = selectedElement.parentNode;

                // Make sure that it is a translated text
                if (text && text.length > 3 && selectedElementParent.classList.contains('language-content')) {
                    // Check the language
                    const language = selectedElementParent.getAttribute('language');

                    if (language !== 'dutch') {
                        // Let's bring up the popup and see if we want to change it
                        this.setState({
                            glossaryText: text,
                            glossaryLanguage: language,
                            showGlossary: true,
                        })
                    }

                }
                // text = window.getSelection().toString();
            }
            
        }
    }

    addToGlossary(language, from, to) {
        // firebase
        // .firestore()
        // .collection(language)
        // .add({
        //     from: from,
        //     to: to,
        // })

        this.closeGlossary();

        // Now that it's saved to the database we will need to rebuild the products array
        const temporaryArray = this.state.productTranslationResult;

        this.state.productTranslationResult = [];

        // Go through the english one
        const newProductArray = temporaryArray.map(product => {
            let newProduct = product;

            if (language === 'english') {
                const { english } = newProduct;

                for (const key in english) {
                    if (english.hasOwnProperty(key)) {
                        let translatedText = replaceGlossaryTerm(english[key], from, to)

                        english[key] = translatedText
                    }
                }

                newProduct.english = english

                return newProduct;
            }
        })

        this.state.productTranslationResult = newProductArray
    }

    closeGlossary() {
        this.state.showGlossary = false;
    }

	render() {
        let content = '';
        let showGlossary = '';
        let showError = '';
        const totalPriceEstimate = () => {
            return (
                // TODO: When more languages are added multiply the character count by the number of languages
                this.state.totalCharacterCount / 50000
            )
        };

        const totalLanguagesDetected = () => {
            return (
                this.state.englishPresent +
                this.state.frenchPresent +
                this.state.germanPresent +
                this.state.spanishPresent
            )
        };

        if (this.state.fileChosen === false) {
            content = (
                <div className="page-container">
                    <h1 className="title">Translate a Product CSV File</h1>
                    <div className="add-file-container">
                        <p className="add-file-title">Choose the product file that has all the information</p>
                        <div className="add-file-input-container">
                            <input id="csv" className="add-file-input" type="file" accept=".csv" />
                            <label className="add-file-label" for="csv">Choose A File</label>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.fileLoading === true) {
            content = (
                <div className="page-container">
                    <h1 className="title">Translate a Product CSV File</h1>
                    <div>
                        <p className="loading-file-title">Loading File</p>
                        <font-awesome-icon className="fa-spinner" icon="spinner" />
                    </div>
                </div>
            );
        } else if (this.state.fileLoaded === true) {
            content = (
                <div className="file-loaded-page-container">
                    <h1 className="title">Translate a Product CSV File</h1>
                    <div>
                        <div className="information-breakdown-container">
                            <div className="breakdown-sub product-count-container">
                                <p className="breakdown-title">Products</p>
                                <p className="breakdown-value">{this.state.totalProducts}</p>
                            </div>
                            <div className="breakdown-sub character-count-container">
                                <p className="breakdown-title">Character Count</p>
                                <p className="breakdown-value">{this.state.totalCharacterCount}</p>
                            </div>
                            <div className="breakdown-sub language-breakdown-container">
                                <p className="breakdown-title">Languages detected</p>
                                <p className="breakdown-value">{totalLanguagesDetected()}</p>
                                <Flag language="'english'" />
                                {/* <Flag v-if="frenchPresent" language="'french'" />
                                <Flag v-if="spanishPresent" language="'spanish'" />
                                <Flag v-if="germanPresent" language="'german'" /> */}
                            </div>
                            <div className="breakdown-sub cost-estimation-container">
                                <p className="breakdown-title">Price Estimate</p>
                                <p className="breakdown-value">€{this.totalPriceEstimate()}</p>
                            </div>
                        </div>
                        <div className="translate-button-container">
                            {!this.state.startedTranslation
                                ? <div className="progress-bar-master">
                                    <div className="progress-bar-container">
                                        <div className="progress-bar-loading-bar">
                                            <div
                                                className="progress-bar-percentage"
                                                // v-bind:style="{ width: progressBarPercentage + '%'}"
                                            ></div>
                                        </div>
                                    </div>
                                    <span className="progress-bar-text">
                                        {this.state.productTranslationResult.length} / {this.state.csvFileObject.length} ({this.state.progressBarPercentage.toFixed(2)}%)
                                    </span>
                                </div>
                                : <button
                                    className="translate-button" 
                                    onClick={this.startTranslation}
                                >
                                    Start Translation
                                </button>
                            }
                            {!this.state.translated
                                ? <button
                                    className="translate-button" 
                                    onClick="exportCSV"
                                >
                                    Export To CSV
                                </button>
                                : ''
                            }
                        </div>
                        <ul id="productResult">
                            {this.state.productTranslationResult.map((translation, index) => {
                                return <TranslatedProduct
                                    key={index}
                                    translatedObject={translation}
                                />
                            })}
                        </ul>
                    </div>
                </div>
            );
        }

        if (this.state.showGlossary) {
            showGlossary = (
                <GlossaryPopup
                    className="glossary-popup"
                    language={this.state.glossaryLanguage}
                    text={this.state.glossaryText}
                    onClose={this.closeGlossary}
                    onAddToGlossary={this.addToGlossary}
                />
            );
        }

        if (this.state.isError) {
            showError = (
                <div className="error-message">
                    <p className="error-span">There was a translation error!</p>
                    <p className="error-span">Please open up developer tools and check the console and send a screen shot to Tyler</p>
                </div>
            )
        }
		return (
            <div className="master-container">
                <div className="please-come-again">
                    <h1>Sorry This Isn't Ready Yet...</h1>
                    <img src={comeAgainGIF} alt="Otter dancing with a fish" />
                </div>
                {content}
                {showGlossary}
                {showError}
            </div>
		)
	}
}

export default TranslateCSV;

/*

<template>
    
</template>

<script>
import Flag from '../components/Flag.vue'
import GlossaryPopup from '../components/GlossaryPopup.vue'
import TranslatedProduct from '../components/TranslatedProduct.vue'
import { deeplTranslate } from '../utils/deepl-translate'
import { replaceGlossaryTerm, asyncForEach } from '../utils/utils'
import { getHeaders, downloadBlob } from '../utils/process-csv'
import parse from 'csv-parse'
import slugify from 'slugify';
import { englishKeys, dutchKeys, translationKeys } from '../utils/consts';

// Firebase
import { db } from '../database/db'

export default {
    components: {
        Flag,
        TranslatedProduct,
        GlossaryPopup,
    },

    data() {
        return {
            
        }
    },

    firestore: {
        englishGlossary: db.collection('english'),
        // frenchGlossary: db.collection('french'),
        // germanGlossary: db.collection('german'),
        // spanishGlossary: db.collection('spanish'),
    },

    computed: {
        totalPriceEstimate() {
            return (
                // TODO: When more languages are added multiply the character count by the number of languages
                this.totalCharacterCount / 50000
            )
        },

        totalLanguagesDetected() {
            return (
                this.englishPresent +
                this.frenchPresent +
                this.germanPresent +
                this.spanishPresent
            )
        },
    },

    mounted() {
        const fileInput = document.getElementById('csv')

        const readFile = async () => {
            // change the UI to reflect the action
            this.state.fileChosen = true
            this.state.fileLoading = true
            const csvFile = fileInput.files[0]

            const reader = new FileReader()

            reader.onload = event => {
                parse(event.target.result, { delimiter: ';', columns: true }, (err, output) => {
                    if (err) {
                        alert('Something seems to be wrong with the CSV File')
                    } else {
                        this.headers = getHeaders(event.target.result)
                        this.csvFileObject = output

                        // Set UI to loaded state
                        this.state.fileLoading = false
                        this.state.fileLoaded = true

                        this.setUpDashboard()
                    }
                })
            }

            reader.readAsText(csvFile)
        }

        if (fileInput) {
            fileInput.addEventListener('change', readFile)
        }

        // Set up selected text
        document.onmouseup = document.onkeyup = () => {
            this.getSelectionText();
        }
    },

    methods: {
        
    },
}
</script> */

import React from "react"
import '../styles/flag.css'

class Flag extends React.Component {
    constructor() {
		super();
		// this.questionTextArea = React.createRef();
        this.state = {};
    }

    render() {
        const imageUrl = () => {
            // return chrome.runtime.getURL(`assets/flags/${this.props.language}.svg`);
            return '';
        };

        return (
            <div class="flag-container">
                <img
                    class="flag-img"
                    src={this.state.imageUrl}
                />
            </div>
        )
    }
}

export default Flag;

import React from "react"
import Head from './Head'
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/faq-blog.css"

class BlogFAQ extends React.Component {
	constructor() {
		super();
		this.questionTextArea = React.createRef();
		this.answerTextArea = React.createRef();
		this.textAreaRef = React.createRef();
		this.state = {
			faqs: [],
			newQuestion: '',
			newAnswer: '',
			copySuccess: '',
		}
	}

	handleInputChange = event => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	editFaq = index => {
		const faqSelected = this.state.faqs[index];
		// Bring in the faq to the edit area
		this.setState({
			newQuestion: faqSelected.question,
			newAnswer: faqSelected.answer,
		});

		// Set values manually
		this.questionTextArea.current.value = faqSelected.question;
		this.answerTextArea.current.value = faqSelected.answer;

		// then delete the faq
		this.deleteFaq(index);

		// Scroll to the top
		window.scrollTo(0, 0);
	}

	deleteFaq = index => {
		// If the array is longer that one we can just use splice
		if (this.state.faqs.length > 1) {
			this.setState({
				faqs: this.state.faqs.splice(index, 1),
			});
		} else {
			// Because there is only one faq we can just set the faqs to an empty array
			this.setState({
				faqs: [],
			});
		}
	}

	copyToClipboard = e => {
		this.textAreaRef.current.select();
		document.execCommand('copy');
		navigator.clipboard.writeText(this.textAreaRef.current.value)
		this.setState({
			copySuccess: 'Copied!'
		});
	}

	addNewFAQ = event => {
		event.preventDefault();

		// Make sure both the question and answer are filled out
		if (this.questionTextArea.current.value.trim().length > 1
			&& this.answerTextArea.current.value.trim().length > 1
		) {
			const { newQuestion, newAnswer } = this.state;
			const newFaq = {
				question: newQuestion,
				answer: newAnswer,
			};
			const newFaqs = [...this.state.faqs, newFaq];

			// Add faq to state
			this.setState({
				faqs: newFaqs,
				newQuestion: '',
				newAnswer: '',
			});

			// Set values manually
			this.questionTextArea.current.value = '';
			this.answerTextArea.current.value = '';

			// update the copy success state
			this.setState({
				copySuccess: ''
			});
		} else {
			alert('Woah there one of the fields are not filled out');
		}
	}

	render() {
		let htmlToCopy = '';
		let visualFaqs = this.state.faqs.length > 0 ? this.state.faqs.map((faq, index) => {
			const { question, answer } = faq;

			return (
				<Card key={index + 1}>
					<Card.Header>
						<Row>
							<Col xs>
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey={index + 1} // For some reason this won't work if the id is 0
								>
									{question}
								</Accordion.Toggle>
							</Col>

							<Col xs="auto" style={{ float: 'right' }}>
								<Button
									variant="secondary"
									onClick={() => this.editFaq(index)}
									style={{ display: 'inline-block' }}
								>
									✏️
								</Button>
								<Button
									variant="secondary"
									onClick={() => this.deleteFaq(index)}
									style={{ display: 'inline-block', marginLeft: '8px' }}
								>
									🗑️
								</Button>
							</Col>
						</Row>
					</Card.Header>
					<Accordion.Collapse
						eventKey={index + 1} // For some reason this won't work if the id is 0
					>
						<Card.Body>{answer}</Card.Body>
					</Accordion.Collapse>
				</Card>
			);
		}) : <div style={{ textAlign: 'center' }}>
				Add A Frequently Asked Question.
			</div >;

		if (this.state.faqs.length > 0) {
			htmlToCopy = `<div class="faq-container" itemscope itemtype="https://schema.org/FAQPage"><h3 class="faq-title">FAQ</h3><div class="faq-questions-container">`;

			this.state.faqs.forEach(faq => {
				htmlToCopy = `${htmlToCopy}
		<div
		  class="faq-question"
		  itemscope
		  itemprop="mainEntity"
		  itemtype="https://schema.org/Question"
		>
		  <h4 class="faq-question-title" itemprop="name">${faq.question}</h4>
		  <div
			class="faq-answer-div"
			itemscope
			itemprop="acceptedAnswer"
			itemtype="https://schema.org/Answer"
		  >
			<div itemprop="text">${faq.answer}</div>
		  </div>`
			});

			// Close the div and add the styling and script
			htmlToCopy = `${htmlToCopy}</div></div>`;
		} else {
			htmlToCopy = 'There are no FAQs yet';
		}

		return (
			<Container className="faq-blog">
				<Head title="Blog FAQ" description="" />
				<Row>
					<Col lg className="faq-col-margin">
						<form
							id="addNewFAQ"
							onSubmit={this.addNewFAQ}
						>
							<div className="question-container">
								<label htmlFor="question">Question</label>
								<textarea
									id="questionTextarea"
									className="question"
									name="newQuestion"
									placeholder="Question"
									ref={this.questionTextArea}
									onChange={this.handleInputChange}
								>
									{this.state.newQuestion}
								</textarea>
							</div>
							<div className="answer-container">
								<label htmlFor="answer">Answer</label>
								<textarea
									id="answerTextarea"
									className="answer"
									name="newAnswer"
									placeholder="Answer"
									ref={this.answerTextArea}
									onChange={this.handleInputChange}
								>
									{this.state.newAnswer}
								</textarea>
							</div>
							<Button
								id="addNewFAQButton"
								onClick={this.addNewFAQ}
							>
								Save
							</Button>
						</form>
					</Col>
					<Col lg className="faq-col-margin">
						<Accordion>
							{visualFaqs}
						</Accordion>
					</Col>
					<Col className="faq-col-margin">
						<div>
							<form>
								<textarea
									ref={this.textAreaRef}
									value={htmlToCopy}
									rows={10}
									style={{ width: '100%' }}
									onChange={event => this.setState({ html: event.target.value })}
								/>
							</form>
							<div>
								<button onClick={this.copyToClipboard}>Copy</button>
								{this.state.copySuccess}
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		)
	}
}

export default BlogFAQ